<template>
	<CreateDialog :dialog="createDialog" v-on:close="createDialog = false">
		<template v-slot:title>
			<span>Create Invoice</span>
			<span v-if="invoice_barcode" class="orange--text text--darken-4 barcode-font-size">
				#{{ invoice_barcode }}</span
			>
		</template>
		<template v-slot:body>
			<v-sheet style="height: calc(100vh - 188px)">
				<v-row>
					<v-col md="12">
						<v-form v-if="createDialog" ref="invoiceForm">
							<v-row>
								<v-col md="9">
									<perfect-scrollbar
										:options="{ suppressScrollX: true }"
										class="scroll custom-box-top-inner-shadow"
										style="position: relative"
									>
										<v-row>
											<v-col md="3">
												<label for="proforma_no" class="mt-4">Proforma</label>
											</v-col>
											<v-col md="9">
												<!-- {{ typeof invoice.proforma }} -->
												<AutoCompleteInput
													hide-details
													:items="proformaList"
													id="proforma_no"
													placeholder="Proforma"
													item-text="barcodeTitle"
													item-value="id"
													:rules="[vrules.required(invoice.proforma, 'Proforma')]"
													:class="{ required: !invoice.proforma }"
													:disabled="proformaInputDisable"
													v-model="invoice.proforma"
													@change="proformaChange"
												>
												</AutoCompleteInput>
												<div class="mt-2" v-if="selectedProforma">
													<div>
														<span class="fw-500">Title : </span>
														<span v-if="selectedProforma.title">{{ selectedProforma.title }}</span>
														<span v-else><em class="text-muted fw-400">No title</em></span>
													</div>
													<div>
														<span class="fw-500">Proforma Amount : </span>
														<span>{{ formatMoney(selectedProforma.amount) }} </span>
													</div>
													<div>
														<span class="fw-500">Approved Amount : </span>
														<span>{{ formatMoney(selectedProforma.approved_amount) }} </span>
													</div>
													<div>
														<span class="fw-500">Balance : </span>
														<span>{{ formatMoney(selectedProforma.balance) }} </span>
													</div>
												</div>
											</v-col>

											<v-col md="3">
												<label for="title" class="btx-label mt-4">Title</label>
											</v-col>
											<v-col md="9">
												<TextInput
													hide-details
													placeholder="Title"
													id="title"
													v-model="invoice.title"
													:disabled="pageLoading"
													:loading="pageLoading"
												>
												</TextInput>
											</v-col>

											<v-col md="3">
												<label for="invoice_no" class="btx-label mt-4">Invoice No</label>
											</v-col>
											<v-col md="3">
												<TextInput
													hide-details
													placeholder="Invoice No"
													id="invoice_no"
													v-model="invoice.invoice_no"
													:disabled="pageLoading"
													:loading="pageLoading"
												>
												</TextInput>
											</v-col>
											<v-col md="3" class="text-right">
												<label for="invoice_date" class="btx-label mt-4">Invoice Date</label>
											</v-col>
											<v-col md="3">
												<DatePicker
													hide-details
													:disabled="pageLoading"
													:loading="pageLoading"
													id="invoice_date"
													v-model="invoice.date"
												></DatePicker>
											</v-col>
											<!-- <v-col md="3">
												<label for="amount" class=" mt-4 ">Status</label>
											</v-col>
											<v-col md="3">
												<AutoCompleteInput v-if="statusList" hide-details :items="statusList"
													id="quotation" placeholder="Status" item-text="text" item-value="value"
													v-model="invoice.status">
												</AutoCompleteInput>
											</v-col> -->
											<v-col md="3">
												<label for="amount" class="btx-label mt-4">Invoice Amount</label>
											</v-col>
											<v-col md="3">
												<PriceInput
													id="amount"
													hide-details
													type="number"
													placeholder="Amount"
													v-model="invoice.invoice_amount"
													disabled
													:loading="pageLoading"
												>
												</PriceInput>
											</v-col>
											<v-col md="3" class="text-right">
												<label for="amount" class="btx-label mt-4 required">Collected Amount</label>
											</v-col>
											<v-col md="3">
												<PriceInput
													id="amount"
													hide-details
													type="number"
													placeholder="Amount"
													v-model="invoice.collected_amount"
													:disabled="pageLoading"
													:rules="[vrules.required(invoice.collected_amount, 'Collected Amount')]"
													:class="{
														required: !invoice.collected_amount,
													}"
													:loading="pageLoading"
													@keyup="collectedAmountChange"
												>
												</PriceInput>
											</v-col>
											<v-col md="3">
												<label for="balance_amount" class="btx-label mt-4">Balance </label>
											</v-col>
											<v-col md="3">
												<PriceInput
													id="balance_amount"
													hide-details
													placeholder="Balance Amount"
													v-model="invoiceBalance"
													disabled
													:loading="pageLoading"
												>
												</PriceInput>
											</v-col>
											<v-col md="6"></v-col>
											<v-col md="3">
												<label for="remark" class="mt-4">Remark</label>
											</v-col>
											<v-col md="9">
												<TextAreaInput
													:disabled="pageLoading"
													:loading="pageLoading"
													id="remark"
													placeholder="Remark"
													v-model="invoice.remark"
												>
												</TextAreaInput>
											</v-col>
											<v-col md="3">
												<label for="attachment" class="btx-label mt-4">Attachment</label>
											</v-col>
											<v-col md="9">
												<FileUpload id="attachment" :allowAddMore="false" v-model="invoice.attachment">
												</FileUpload>
											</v-col>
											<!-- </v-row> -->
											<!-- <v-col md="3" class="text-right">
												<label for="invoice_no" class="btx-label mt-4">Invoice No</label>
											</v-col>
											<v-col md="3">
												<TextInput
													id="invoice_no"
													hide-details
													placeholder="Invoice No"
													v-model="invoice.invoice_no"
													:disabled="pageLoading"
													:loading="pageLoading"
												>
												</TextInput>
											</v-col> -->
											<!-- <v-row>
											</v-row> -->
										</v-row>
									</perfect-scrollbar>
								</v-col>
							</v-row>
						</v-form>
					</v-col>
				</v-row>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$router.go(-1)"> Close </v-btn>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
				Save
			</v-btn>
		</template>
	</CreateDialog>
</template>
<script>
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
// import Dialog from "@/view/components/Dialog";
import CreateDialog from "@/view/components/CreateDialog";
import TextInput from "@/view/components/TextInput";
import PriceInput from "@/view/components/PriceInput";
import DatePicker from "@/view/components/DatePicker";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";
import { getMilestoneByUUID, GetProject } from "@/core/lib/project.lib";
import { mapGetters } from "vuex";
import { createInvoice, getProformaDropDown } from "@/core/lib/project.lib";
export default {
	name: "create-invoice",
	title: "create invoice",
	components: {
		// Dialog,
		// ShowValue,
		TextInput,
		TextAreaInput,
		PriceInput,
		FileUpload,
		AutoCompleteInput,
		// ShowPrice,
		// Chip,
		CreateDialog,
		DatePicker,
	},

	data() {
		return {
			createDialog: true,
			pageLoading: false,
			invoice_barcode: null,
			statusList: [
				{
					value: 1,
					text: "PAID",
				},
				{
					value: 2,
					text: "UNPAID",
				},
				{
					value: 3,
					text: "PARTIAL-PAID",
				},
			],
			proformaList: [
				// {
				// 	text: "PF001",
				// 	value: 1,
				// },
				// {
				// 	text: "PF002",
				// 	value: 2,
				// },
				// {
				// 	text: "PF003",
				// 	value: 3,
				// }
			],
			invoice: {
				title: null,
				invoice_amount: null,
				collected_amount: null,
				balance_amount: null,
				proforma: null,
				invoice_no: null,
				status: null,
				attachment: null,
				date: null,
			},
			projectUUID: null,
			mileUUID: null,
			project: null,
			milestone: null,
			selectedProforma: null,
		};
	},
	methods: {
		async getProject() {
			try {
				// this.pageLoading = true;
				const project = await GetProject(this.$route.params.uuid);
				this.project = project[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				// this.pageLoading = false;
			}
		},
		async getMilestone() {
			try {
				this.pageLoading = false;
				const milestone = await getMilestoneByUUID(this.$route.query.mileUUID);
				this.milestone = milestone[0];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.invoiceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.invoiceForm.validate()) {
				return false;
			}
			const formData = {
				project: _this.project.id,
				proforma: _this.selectedProforma.id,
				invoice_no: _this.invoice.invoice_no,
				title: _this.invoice.title,
				amount: _this.invoice.invoice_amount,
				date: _this.invoice.date,
				collected_amount: _this.invoice.collected_amount,
				remark: _this.invoice.remark,
				files: _this.invoice.attachment,
			};
			try {
				_this.pageLoading = true;
				await createInvoice(formData);
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Invoice has been created." },
				]);
				_this.goBack();
				// _this.invoice = {
				// 	title: null,
				// 	amount: null,
				// 	invoice_no: null,
				// 	attachment: null,
				// 	date: null,
				// };
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async proformaChange() {
			const data = this.proformaList.filter((ele) => this.invoice.proforma === ele.id);
			this.selectedProforma = data[0];
			if (this.invoice.proforma) {
				this.invoice.invoice_amount = data[0].balance;
			}
		},
		async getProformaList() {
			this.pageLoading = true;
			try {
				const { proformas } = await getProformaDropDown(this.$route.query.mileUUID);
				this.proformaList = proformas;
				if (this.proformaList.length) {
					this.proformaList.forEach((ele) => {
						if (ele.title) {
							ele.barcodeTitle = ele.barcode + "-" + ele.title;
						} else {
							ele.barcodeTitle = ele.barcode;
						}
					});
				}
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
		collectedAmountChange() {
			if (this.invoice.collected_amount > this.invoice.invoice_amount) {
				this.invoice.collected_amount = this.invoice.invoice_amount;
			}
		},
		async generateBarcode() {
			this.genrateBarcode("invoice").then((output) => {
				this.invoice_barcode = output;
			});
		},
	},
	computed: {
		...mapGetters(["errors"]),
		invoiceBalance: {
			get() {
				if (this.selectedProforma?.balance - this.invoice.collected_amount) {
					return this.selectedProforma?.balance - this.invoice.collected_amount;
				} else {
					return 0;
				}
				// return this.selectedProforma?.balance - this.invoice.collected_amount
			},
			// setter
			set(newValue) {
				// Note: we are using destructuring assignment syntax here.
				// [this.firstName, this.lastName] = newValue.split(' ')
				return newValue;
			},
		},
		proformaInputDisable() {
			if (this.$route.query.proformaUUID || this.pageLoading) {
				return true;
			} else {
				return false;
			}
		},
	},
	async mounted() {
		// // const { uuid } = this.$route.params;
		// // this.projectUUID = uuid;
		// await this.getMilestone()

		await this.getProformaList();
		await this.getProject();
		await this.generateBarcode();
		if (this.$route.query?.proformaUUID) {
			this.invoice.proforma = Number(this.$route.query?.proformaUUID);
			this.proformaChange();
		}
		// if (this.$route.query?.proformaUUID) {
		// 	this.invoice.proforma = this.$route.query?.proformaUUID
		// }
		// await this.loadProforma()
		// // await this.getinvoice()
	},
};
</script>
